@import "../node_modules/bootstrap/scss/functions";
@import "./assets/scss/variables";
@import "./assets/scss/main";
@import "./assets/scss/custom-modal.scss";
@import "../node_modules/bootstrap/scss/bootstrap";


@font-face {
  font-family: "Walr Inter";
  src: url("./assets/fonts/Inter-Medium.woff2") format("woff2"), url("./assets/fonts/Inter-Medium.woff") format("woff");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Walr Inter";
  src: url("./assets/fonts/Inter-SemiBold.woff2") format("woff2"), url("./assets/fonts/Inter-SemiBold.woff") format("woff");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Walr Inter";
  src: url("./assets/fonts/Inter-Bold.woff2") format("woff2"), url("./assets/fonts/Inter-Bold.woff") format("woff");
  font-style: normal;
  font-weight: 700;
}

* :not(i, .fa, .fas, .far, .k-icon) {
  font-family: "Walr Inter", sans-serif !important;
  -moz-osx-font-smoothing: grayscale;
  /* Firefox */
  -webkit-font-smoothing: antialiased;
  /* WebKit  */
}

.navbar-placeholder {
  height: 100%;
  padding-left: 360px;
}