:root {
  --bs-border-color: #e7e7e7 !important;
}

.accordion {
  --bs-accordion-btn-icon: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQgN0w4IDExTDEyIDciIHN0cm9rZT0iIzE3MjE0NyIgc3Ryb2tlLXdpZHRoPSIxLjciIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz4KPC9zdmc+Cg==") !important;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQgN0w4IDExTDEyIDciIHN0cm9rZT0iIzE3MjE0NyIgc3Ryb2tlLXdpZHRoPSIxLjciIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz4KPC9zdmc+Cg==") !important;
}

body {
  overflow-x: hidden;
  color: #19394c;
  --bs-body-font-size: 0.875rem;
}

small,
.small {
  font-size: 0.75rem !important;
}

.medium {
  font-size: 0.8125rem !important;
}

.card {
  --bs-card-border-color: #e7e7e7 !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

svg {
  fill: $primary;
}

.regular {
  font-weight: 500;
}

h6 {
  font-size: 0.875rem !important;
  font-weight: 600 !important;
}

input,
button,
.form-control {
  font-weight: 500 !important;
}

.w-40 {
  width: 40% !important;
}

.w-15 {
  width: 15% !important;
}

.w-85 {
  width: 85% !important;
}

.max-width-85 {
  max-width: 85px !important;
}

.max-width-90 {
  max-width: 90px !important;
}

.max-width-500 {
  max-width: 550px !important;
}

.max-width-800 {
  max-width: 800px;
}

.h-0 {
  height: 0;
  overflow: hidden;
}

.box-shadow-none {
  box-shadow: none !important;
}

.z-index-2000 {
  z-index: 2000;
}

.border-dashed {
  border-style: dashed !important;
}

.modal-95w {
  display: flex;
  margin: 1.14% auto 0.86% auto !important;
  height: 97.5% !important;
  width: 98% !important;
  max-width: none !important;
}

.placeholder-primary::placeholder {
  color: #0a1a28 !important;
}

#surveyTooltip {
  &>.tooltip-inner {
    color: #fff;
    background-color: #0a1a28;
    font-size: 12px;
    text-align: left;
  }

  &>.tooltip-arrow {
    border-color: #0a1a28;
  }
}

.otp-wrapper {
  margin-bottom: 1rem;

  &>input {
    border: 0.0625rem solid;
    border-color: #dedfdf;
    border-radius: 0.5rem;
    width: 3.125rem !important;
    height: 3.5rem !important;
    font-size: 1.375rem;
    transition: 0.2s;
    color: $primary;
    margin: 0 0.5rem 1rem 0 !important;

    &:focus-visible {
      outline: none;
      border: 0.125rem solid $primary;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgba(25, 57, 76, 0.15);
    }
  }
}

#spinner-wrapper {
  width: 100%;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  #spinner {
    width: 80px;
    height: 80px;
    border: 2px solid rgba(117, 117, 117, 0.3);
    border-top: 3px solid #0a1a28;
    border-radius: 100%;
    margin: 0 auto;
    align-self: center;
    animation: spin 1s infinite linear;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Modal */

.modal-dialog {
  transform: none;
  height: calc(100% - 56px);
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-content {
    animation: modal-animation;
    animation-duration: 0.2s;
    animation-iteration-count: 1;
    animation-fill-mode: both;
    width: 20rem !important;
    box-shadow: rgba(20, 19, 33, 0.08) 0rem 0rem 0rem 0.0625rem, rgba(20, 19, 33, 0.4) 0rem 1rem 1.5rem -0.5rem !important;
    border-radius: var(--bs-modal-inner-border-radius);
    margin: 0;
    min-width: 500px;
    width: 500px !important;
  }

  .modal-header,
  .modal-footer {
    border-radius: 0;
  }

  .modal-title {
    font-size: 14px;
    font-weight: 600;
  }

  .modal-body {
    font-size: 14px;
  }

  .btn-secondary {
    color: $primary !important;
    border-color: rgba($primary, 0.2) !important;
  }

  .delete {
    width: 32px;
    cursor: pointer;
  }
}

@keyframes modal-animation {
  from {
    margin-top: 0;
    opacity: 0;
  }

  to {
    margin-top: -0.5rem;
    opacity: 1;
  }
}

/* Bootstrap override */

.btn-secondary {
  color: $primary !important;
  border-color: $primary !important;
}

.bold {
  font-weight: 600 !important;
}

.form-control,
.form-select,
.form-label {
  font-size: 0.875rem !important;
  font-weight: 500;
  color: $primary !important;
  display: flex;
  border-color: #dddfe1 !important;

  &:disabled {
    color: rgba($primary, 0.5) !important;
    background-color: rgba($gray-100, $alpha: 1) !important;
  }

  &:focus {
    border-color: #8c9ca6 !important;
  }

  &.border-right-0 {
    border-right: 0 !important;
  }

  &.is-invalid {
    border-color: #dddfe1 !important;
  }
}

.form-check-label,
.form-check-input {
  cursor: pointer;
}

.form-check-input {
  height: 1.25rem !important;
  width: 1.25rem !important;
  min-width: 1.25rem !important;
  margin-top: 0 !important;
}

.input-group-text {
  border-radius: 0 0.375rem 0.375rem 0 !important;
  padding-left: 0 !important;
  font-weight: 500 !important;
}

.btn {
  font-size: 0.8125rem !important;
  font-weight: 600 !important;
  transition: 0.2s !important;

  &:active {
    transform: scale(0.99);
  }
}

.border-radio {
  border: 1px solid #dddfe1;
}

.form-switch .form-check-input {
  height: 1.2em;
  width: 2em !important;
  margin-right: 0.5rem;
  margin-top: 0;
  border: 0.125rem;
  background-color: rgba($primary, 0.2);
  cursor: pointer;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9Jy00IC00IDggOCc+PGNpcmNsZSByPSczJyBmaWxsPScjZmZmJy8+PC9zdmc+") !important;

  &:active {
    filter: none !important;
  }
}

/* Custom classes */
.multi-select {
  &.react-select__control {
    border: 0.0625rem solid #dddfe1 !important;
    border-radius: 0.375rem;
    min-height: 2.1875rem;
    max-height: 2.1875rem;

    &:focus,
    &.react-select__control--is-focused {
      border-color: #8c9ca6 !important;
      outline: 0 !important;
      box-shadow: 0 0 0 0.25rem rgba(25, 57, 76, 0.25) !important;
    }
  }

  .react-select__placeholder {
    font-size: 0.875rem;
    color: $primary;
    margin-top: 1px;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__value-container {
    margin-top: -0.1875rem;
  }

  .react-select__multi-value {
    background: rgba($primary, 0.2) !important;

    .react-select__multi-value__label {
      color: $primary !important;
    }

    .react-select__multi-value__remove svg {
      fill: $primary !important;
    }
  }

  .react-select__dropdown-indicator {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNiAxNic+PHBhdGggZmlsbD0nbm9uZScgc3Ryb2tlPScjMzQzYTQwJyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1saW5lam9pbj0ncm91bmQnIHN0cm9rZS13aWR0aD0nMicgZD0nbTIgNSA2IDYgNi02Jy8+PC9zdmc+") !important;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    margin-top: -2px;
    cursor: pointer;

    svg {
      opacity: 0;
    }
  }

  .react-select__clear-indicator {
    opacity: 0;
  }
}

.react-select__menu {
  bottom: 100% !important;
  top: initial !important;
  border-radius: 0.375rem !important;
  font-size: 0.875rem;
  margin-bottom: 0.25rem !important;
  margin-top: 0 !important;
  color: $primary !important;

  .react-select__option:hover,
  .react-select__option--is-focused {
    background: #dddfe1 !important;
  }
}

.ps-default {
  padding-left: 8rem !important;
}

.legal {
  font-size: 0.8125rem;
  line-height: 1.375rem;
}

.external {
  opacity: 0.7;

  svg {
    fill: $white;
  }

  &:hover {
    opacity: 1;
  }
}

.walr-bids-badge {
  background: $walr;
  color: $primary;
  padding: 0.375rem 0.75rem;
  border-radius: 10rem;
  font-size: 0.8125rem;
  width: fit-content;
}

.walr-bids-pills {
  p {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 24px 12px 32px;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05), 0px 4.15751px 14.5513px rgba(0, 0, 0, 0.08);
    border-radius: 100px;
    width: fit-content;
    font-size: 3.5rem;
    line-height: 3.875rem;
    letter-spacing: -0.03em;
  }

  .light {
    background: $white;
    color: $primary;
  }

  .dark {
    background: $primary;
    color: $white;
  }

  .indent-2 {
    margin-left: 7rem;
  }

  .indent-3 {
    margin-left: 2rem;
  }
}

.table-markets {
  .btn {
    border: 1px dashed $gray-400 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500 !important;

    &:hover {
      border: 1px dashed $gray-500 !important;
    }

    &.delete {
      border: 0 !important;
    }
  }

  .header {
    padding: 0 !important;
    font-size: 14px;
    color: $primary;
    width: auto;

    &.fixed {
      width: 100px;
    }
  }

  .table> :not(caption)>*>* {
    border: 0;
    box-shadow: none;
    padding: 0;
  }

  thead {
    height: 25px;
    vertical-align: top;
  }

  tbody {
    background: rgba($primary, 0.025);
    border-bottom: 2px solid rgba($primary, 0.1) !important;
    margin-bottom: 4px;

    .delete {
      width: 32px;
    }

    &.fixed {
      width: 100px;
    }

    tr {
      .spacing {
        margin: 12px 0 0;
      }
    }

    tr:last-child {
      .spacing {
        margin: 12px 0;
      }
    }
  }
}

.table-summary {
  background: transparent;
  border: 1px solid #e5e7eb;
  font-size: 14px;

  table {
    margin: 0;
  }

  th {
    width: 20%;
    vertical-align: middle;
    border: none;
  }

  tr:last-child {
    td {
      vertical-align: top;
    }
  }
}

.review-markets,
.review-estimate {

  button {
    padding: 12px 16px !important;

    p {
      font-size: 14px !important;
    }
  }

  small {
    font-size: 14px !important;
  }
}

.walr-graphic {
  background: #c1f4e9;
  position: absolute;
  height: 20rem;
  width: 100%;
  left: 0;
  top: -12rem;
  z-index: -1;
  transform: skewY(5deg) translateY(-0.0219rem);
}

.walr-illustration {
  background: url("/assets/img/walr_illustration.png") no-repeat;
  background-size: cover;
  height: calc(75rem + 2vw);
  width: calc(75rem + 2vw);
  position: absolute;
  left: 46rem;
  top: -40rem;
  z-index: -1;
}

/// Tabs ///

.targeting-tabs {
  .nav {
    background: $white;
  }

  .nav-item {
    border-radius: 0 !important;
    color: $gray-600;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
    border: none;
    cursor: pointer;
    height: 3rem;
    min-height: 3rem;
    line-height: 3rem;
    margin: 0;
    outline: none;
    overflow: hidden;
    padding: 0 12px;
    position: relative;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    box-shadow: inset 0 0 0 0 $gray-200;

    &.active {
      background-color: #ffffff !important;
      color: $primary !important;
      border: none;
      box-sizing: border-box;
      box-shadow: inset 0 -1px 0 0 $primary, 0 1px 0 0 $primary !important;
    }

    &:before {
      bottom: 0;
      content: "";
      display: block;
      height: 0%;
      left: 0;
      position: absolute;
      transition: height 125ms cubic-bezier(0.66, 0, 0, 1) !important;
      width: 100%;
    }

    &:hover {
      color: $gray-700;
      background-color: initial !important;

      &:before {
        background-color: rgba($black, 0.03);
        height: 100%;
      }
    }
  }
}