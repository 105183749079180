.cs-audience-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($color: #000000, $alpha: 0.5);

  .w-100-200 {
    width: calc(100% - 240px);
  }

  .accordion {
    --bs-accordion-color: $primary !important;

    .accordion-button {
      &:focus {
        z-index: 3;
        border-color: unset;
        outline: 0;
        box-shadow: none;
      }

      &:not(.collapsed) {
        color: inherit;
        background-color: transparent;
        box-shadow: none;
      }
    }
  }

  .cs-audience-modal {
    width: calc(100% - 32px);
    height: calc(100% - 32px);
    max-height: calc(100% - 32px);
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 8px;

    .cs-audience-body {
      height: 100%;
      max-height: 100%;
      overflow: hidden;
      display: flex;
      flex: 1;

      .cs-audience-sidebar {
        height: 100%;
        max-width: 300px;
        max-height: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow-y: scroll !important;

        .cs-audience-input-group {
          display: flex;
          justify-content: flex-start;

          .cs-audience-input-text {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.375rem 0.25rem 0.375rem 0.5rem;
            border-left: 1px solid $gray-400;
            border-top: 1px solid $gray-400;
            border-bottom: 1px solid $gray-400;
            border-right: transparent;
            border-radius: 0.375rem 0 0 0.375rem;

            svg {
              transform: scale(0.9);
              opacity: 0.8;
            }
          }

          .cs-audience-search {
            display: block;
            width: 100%;
            padding: 0.375rem 0.5rem 0.375rem 0;
            font-size: 0.825rem;
            font-weight: 500;
            line-height: 1.5;
            background-color: #fff;
            background-clip: padding-box;
            border-top: 1px solid $gray-400;
            border-bottom: 1px solid $gray-400;
            border-right: 1px solid $gray-400;
            border-left: transparent;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border-radius: 0 0.375rem 0.375rem 0;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            outline: none;

            svg {
              transform: scale(0.9);
            }

            &::placeholder {
              color: #172147;
              opacity: 0.5;
            }

            &::-webkit-search-cancel-button {
              background: none;
              -webkit-appearance: none;
            }
          }
        }

        .cs-side-accordion-wrapper {
          max-height: 100%;

          .accordion {
            border: none !important;

            .accordion-button {
              padding-left: 4px;
              border-radius: 6px;
              color: $primary;

              &:not(.collapsed) {
                // color: red;
                background-color: transparent;
                box-shadow: none;
              }

              &:hover {
                background: $gray-200;
              }

              h6 {
                line-height: 24px;
              }

              &::after {
                flex-shrink: 0;
                width: 0;
                height: 0;
                margin-left: auto;
                content: "";
                background-image: var(--bs-accordion-btn-icon);
                background-repeat: no-repeat;
                background-size: var(--bs-accordion-btn-icon-width);
                transition: var(--bs-accordion-btn-icon-transition);
              }

              &:not(.collapsed)::after {
                background-image: var(--bs-accordion-btn-active-icon);
                transform: var(--bs-accordion-btn-icon-transform);
              }

              &::before {
                flex-shrink: 0;
                width: 16px;
                height: 16px;
                margin-left: 0;
                margin-right: 4px;
                content: "";
                background-image: var(--bs-accordion-btn-icon);
                background-repeat: no-repeat;
                background-size: 16px;
                transform: rotate(-90deg);
                transition: var(--bs-accordion-btn-icon-transition);
              }

              &:not(.collapsed)::before {
                background-image: var(--bs-accordion-btn-active-icon);
                transform: rotate(0deg);
              }
            }

            .accordion-item {
              outline: none;
              border: none !important;

              &:last-child {
                padding-bottom: 1rem;
              }
            }

            .accordion-question {
              box-shadow: rgba($primary, 0.08) 0px 0px 0px 1px, rgba($primary, 0.32) 0px 4px 4px -4px;
              padding: 0.75rem;
              font-size: 0.8438rem;

              &:hover {
                box-shadow: rgba($primary, 0.16) 0px 0px 0px 1px, rgba($primary, 0.32) 0px 4px 4px -4px;
                cursor: pointer;

                svg {
                  opacity: 1;
                }
              }

              svg {
                opacity: 0.5;
              }

              &.disabled {
                opacity: 0.5;
                cursor: not-allowed !important;
              }
            }
          }
        }

        .cs-audience-tooltip {
          cursor: pointer;
          position: relative;

          &-message {
            visibility: hidden;
            background: #000;
            color: #fff;
            border-radius: 5px;
            padding: 5px;
            opacity: 80%;
            position: absolute;
            bottom: 1rem;
            max-width: 170px;
            width: fit-content;
            left: 0;
          }

          &:hover {

            span {
              visibility: visible;
            }
          }
        }
      }

      .cs-audience-main {
        height: 100%;
        max-height: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;
        background: #f5f5f6;

        .cs-main-questions-wrapper {
          position: relative;
          height: 100%;
          max-height: 100%;
          overflow-y: auto !important;
          background: #f5f5f6;

          .accordion {
            box-shadow: 0rem 0.0625rem 0.1875rem rgba($primary, 0.07), 0rem 0.0625rem 0.125rem rgba($primary, 0.05);
            border: 0;

            .accordion-item {
              border-radius: 0 !important;
              border: 0;

              .answer-row {
                &:hover {
                  background: $gray-200;

                  .handle {
                    opacity: 1;
                  }
                }

                &.disabled {
                  opacity: 0.5;
                  pointer-events: none;
                }

                &.dragging {
                  background: $primary;
                  color: $white;
                  width: 320px !important;

                  .answer-text {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    width: 240px;
                    display: block;
                    overflow: hidden;
                  }

                  .input-group {
                    display: none;
                  }

                  .form-check-input,
                  .form-check-input:checked {
                    background-color: rgba($white, 0.5);
                    border-color: rgba($white, 0.5);
                  }

                  .handle {
                    opacity: 0.5;
                    fill: $white;
                  }
                }

                .handle {
                  opacity: 0.5;
                }
              }

              .accordion-header {
                .form-check {
                  margin: 0;
                }

                .divider {
                  width: 1px;
                  height: 28px;
                  background: rgba($primary, 0.15);
                  margin: 0 12px;
                }
              }

              .accordion-button {
                position: relative;
                border-radius: 0 !important;
                padding: 1.25rem !important;

                &::after {
                  position: absolute;
                  right: 1.5rem;
                  margin-left: 0;
                }
              }
            }

            .accordion-body {
              padding: 1.25rem;

              .cs-new-group-wrapper {
                transition: all 0.5s ease;

                .dropdown-toggle {
                  &::after {
                    display: none;
                  }
                }
              }
            }
          }

          .cs-dashed-box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            outline: 1px dashed rgba($primary, 0.2);
            min-height: 50px;
          }

          .empty {
            border: 1px dashed $gray-500;
            pointer-events: none;
          }
        }
      }
    }

    .cs-audience-footer {
      display: flex;
      justify-content: flex-end;
    }

    .cs-input-switch {
      cursor: pointer;
      display: flex;
      align-items: center;
      background: #f3f4f4;
      border-radius: 0.375rem;
      padding: 2.25px;
      color: rgba($color: #172147, $alpha: 0.5);

      .cs-input-item {
        padding: 0.35rem 0.725rem;
        border-radius: 0.35rem;
        user-select: none;
      }

      .active {
        color: #172147;
        background-color: #fff;
      }
    }
  }
}