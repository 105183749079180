.Navbar {
  position: fixed;
  width: 22.5rem;
  background: #0d2934;

  & li {
    cursor: pointer;
    height: 1.25rem;
  }

  .bg-navbar {
    background: #0d2934;
  }

  .nav-open {
    height: 1.25rem;
  }

  .nav-close {
    height: 0 !important;
    margin: 0 !important;
  }

  .stepper {
    background-color: rgba($color: #fff, $alpha: 0.1);

    & > li {
      width: 1.25rem;
      max-width: 1.25rem;
      height: 1.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.6875rem;
      font-weight: bold;
    }

    .stepper-item {
      background-color: rgba($color: #fff, $alpha: 0.2);
      color: white;
    }

    .stepper-dot-item {
      background-color: rgba($color: #fff, $alpha: 0);
      top: 0.0625rem;

      .stepper-dot {
        width: 0.375rem;
        height: 0.375rem;
        background-color: rgba($color: #fff, $alpha: 0.2);
      }
    }

    .stepper-dot.active,
    .list-group-item.active {
      z-index: 2;
      color: var(--bs-list-group-active-color) !important;
      background-color: var(--bs-list-group-active-bg);
      border-color: var(--bs-list-group-active-border-color);
    }
  }

  .list-group-item.active {
    z-index: 2;
    margin-top: 0 !important;
    color: var(--bs-list-group-active-bg) !important;
  }
}
